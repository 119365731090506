import React from "react"
import ReactPlayer from 'react-player'

import "./video.css"

const Video = ({url}) => {
  console.log(url)
  return (
    <div className='player-wrapper'>
      <ReactPlayer
        className='react-player'
        url={ url }
        width='100%'
        height='100%'
        controls
      />
    </div>
  )
}

export default Video
